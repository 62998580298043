.option.click2 {
  background: url("./Components/assets/images/about/hospital.webp") no-repeat center center;
  background-size: cover;
}

.option.click3 {
  background: url("./Components/assets/images/about/click3.png") no-repeat center center;
  background-size: cover;
}

.option.click1 {
  background: url("./Components/assets/images/about/club.jpg") no-repeat center center;
  background-size: cover;
}

.slick-track {
  display: flex !important;
  gap: 15px !important;
}

#quote {
  padding-bottom: 50px;
}

.form-custom {
  background-color: #fff;
  border-radius: 28px;
  padding: 36px 34px;
  /* border: 1px solid #021338; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.form-custom input {
  color: #3c4043;
}

.logo img {
  width: 157px;
  height: 46px;
}

.footer-logo img {
  width: 157px;
  height: 46px;
}

.navbar-custom .get-quote-btn {
  background-color: #f3cb5f;
  color: #032b52;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 14px;
  border-radius: 14px;
  display: inline-block;
}

.navbar-custom .get-quote-btn>a {
  display: inline-block;
}

/* .about-indetails {
    flex-wrap: nowrap;
} */

.about-indetails>div {
  width: 50%;
  padding: 0px 5px;
}

.about-indetails>div>p {
  line-height: 22px;
  font-size: 15px;
}

.footer-icons {
  height: 16px;
  width: 38px;
}

@media screen and (max-width: 1199px) {
  .main-headerwrap {
    margin-top: 0;
  }

  .we-provide-item .thumb .mimg {
    height: 280px;
  }

  .cleaning-quote>img {
    display: none;
  }
}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 768px) {
  .we-provide-item .thumb .mimg {
    height: 240px;
  }

  .form-custom {
    padding: 26px 24px;
  }
}

@media screen and (max-width: 576px) {
  .nav-brand {
    width: 115px;
  }

  .navbar-custom .get-quote-btn {
    background-color: #f3cb5f;
    color: #032b52;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 12px;
    border-radius: 14px;
  }

  .about-indetails>div {
    width: 50% !important;
  }

  .about-indetails>div .expert-head {
    margin-bottom: 6px !important;
  }

  .footer-icons {
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  .nav-brand {
    width: 105px;
  }

  .navbar-custom .get-quote-btn {
    padding: 4px 10px;
    border-radius: 14px;
  }

  .about-indetails>div {
    width: 100% !important;
  }
}